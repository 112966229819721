/**
 * _base.scss
 * Estilos generales utilizados como base para la reutilizacion de clases
 *
 * Index
 * - Contenido general
 * - Colores de fondo
 * - Enlaces normales unicamente texto
 * - Boton de icono
 * - Contenedor general del panel
 * - Contenedor general grid de las cards en el panel
 * - Link general de cards
 * - Cards generales
 * - Card icono
 * - Card titulos
 * - Contenedor pantalla completa
 * - Contenido centrado en el viewport
 * - Contenedor parcial de la pantalla
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
 * Contenido general
 *
 * Estable la imagen de fondo , su posicionamiento central y fijo, los colores iniciales de los textos y la fuente utilizada en el documento
 */
body {
  background-attachment: fixed;
  background-image: url("../../../assets/img/banner_business_car_3.webp");
  background-position: center center;
  background-size: cover;
  color: variables.$generalcolor;
  font-family: variables.$fontFamily;
}

/**
 * Colores de fondo
 *
 * Define los colores base utilizados como fondo
 */
.background_white {
  background: variables.$colorwhite;
}

.background_black {
  background: variables.$colorblack;
}

/**
 * Enlaces normales unicamente texto
 *
 * Define el color del texto y la pseudoclase hover en caso de contar con un icono svg se agrega un margen a dicho icono
 */
.link_normal {
  color: variables.$colorp2;
  font-weight: 600;

  &:hover {
    color: variables.$colorp1;
  }

  svg {
    margin-right: 10px;
  }

  .not_action {
    color: variables.$generalcolor;
    font-weight: 400;
    margin-right: 10px;
  }
}

/**
 * Boton de icono
 *
 * Define los colores del boton para realizar acciones simples
 */
.button_icon {
  @include mixins.shapeContent(none, none, 50%, 32px, 32px);
  color: variables.$colorp3;

  &:hover {
    background: variables.$colorp6;
  }
}

/**
 * Contenedor general del panel
 *
 * Se establece el ancho maximo que abarca en la pantalla y margenes superiores
 */
.container_panel {
  margin: auto;
  margin-top: 100px;
  max-width: 1400px;
  padding: 15px;

  @include mixins.small {
    margin-top: 20px;
  }
}

/**
 * Contenedor general grid de las cards en el panel
 *
 * Se establece el grid que contendra cada una de las cards de los módulos en versión móvil y escritorio
 */
.container_general_card {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

  @include mixins.small {
    gap: 10px;
  }
}

/**
 * Link general de cards
 *
 * Define el color del texto en las cards que actúan como enlaces
 */
.link_general_card {
  color: variables.$generalcolor;

  &:hover {
    color: variables.$generalcolor;
  }
}

/**
 * Cards generales
 *
 *  Se define la card normal y card con efecto blur para elcontenido de los módulos
 */
.general_card,
.general_card_blur {
  @include mixins.generalShadow(
    rgba(255, 255, 255, 0.6),
    0 1px 15px 0 rgb(0 0 0 / 12%)
  );
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 15px;
  position: relative;
}

.general_card_blur {
  backdrop-filter: blur(20px);

  &:hover {
    .icon_card:before {
      @include mixins.statusVisibility(1, visible);
      transform: scale(1);
    }

    .icon_card img {
      filter: invert(1);
    }

    .icon_card svg {
      color: variables.$colorwhite;
    }
  }
}

/**
 * Cards icono
 *
 *  Se define el tamaño y colores de fondo del icono del módulo
 */
.icon_card {
  @include mixins.shapeContent(rgba(0, 0, 0, 0.1), none, 5px, 50px, 50px);
  @include mixins.contentCenterXY;
  position: relative;
  z-index: 1;

  &:before {
    @include mixins.pseudoelementoTotalLimit;
    @include mixins.shapeContent(
      linear-gradient(45deg, variables.$colorp2, variables.$colorp1),
      none,
      5px,
      100%,
      100%
    );
    @include mixins.statusVisibility(0, hidden);
    transform: scale(2);
    transition: 0.3s ease-in;
    z-index: -1;
  }

  svg {
    color: variables.$generalcolor;
    font-size: 24px;
  }

  img {
    height: 24px;
    width: 24px;
  }
}

/**
 * Card titulos
 *
 *  Se establecen los tamaños de los titulos y su alineacion horizontal con su modo responsive
 */
.title_icon_card {
  h2,
  h3 {
    text-align: center;
  }

  h2 {
    font-size: 16px;

    @include mixins.small {
      font-size: 14px;
    }
  }

  h3 {
    color: variables.$colorp3;
    font-size: 12px;
  }
}

.general_card:hover .title_icon_card h2 {
  color: variables.$generalcolor;
}

/**
 * Contenedor pantalla completa
 *
 *  Se establece el alto y ancho de toda la pantalla para mostrar contenido
 */
.complete_screen {
  height: 100vh;
  width: 100%;
}

/**
 * Contenido centrado en el viewport de la pantalla
 *
 *  Se establece de forma centrada todo lo que contenga el contenido en la posicion X y Y
 */
.content_center_screen {
  @include mixins.contentCenterXY;
  padding: 15px;
}

/**
 * Contenedor parcial de la pantalla
 *
 *  Define un contenedor que aborda unicamente una parte del ancho de la pantalla con la altura total del viewport
 */
.partial_complete_screen {
  @include mixins.contentCenterXY;
  height: 100vh;
  overflow-y: auto;
  padding: 15px;
}
