/**
 * _variables.scss
 * Colores y medidas para dispositivos modo responsive
 *
 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
// @import url('http://fonts.cdnfonts.com/css/proxima-nova-2');
$fontFamily: "Poppins", sans-serif;
$colorp1: #882439;
$colorp2: #e33333;
$colorp3: #585858;
$colorp4: #c3c7cc;
$colorp5: #f9f9f9;
$colorp6: #dfdfdf;
$colorp7: #7f7f95;
$colorwhite: #fff;
$generalcolor: #323232;
$colorblack: #1c1c1c;
$colorerror: #e30939;
$colorsuccess: #08d081;
$colorwarning: #f5c41d;
$colorbaseskeleton: #f3f3f3;
$colorbaseskeletoninternal: #e3e3e3;
$xlarge-width: 1399px;
$large-width: 1199px;
$medium-width: 991px;
$small-width: 767px;
$xsmall-width: 575px;
$xsmall479-width: 479px;
$gradientPrimary: linear-gradient(-45deg, $colorp1 0%, $colorp2 100%);
$gradientBlack: linear-gradient(-45deg, $generalcolor, $colorblack);
$shadowPrimary: 0px 2px 12px rgb(136 36 57 / 30%);
