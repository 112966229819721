/**
 * _mixins.scss
 * Estilos para la declaraciones de mixins
 *
 * Index
 * - Fondo y sombra
 * - Posicionamiento absoluto
 * - Visibilidad
 * - Input
 * - Espaciado input buscador
 * - Forma general
 * - Contenido centrado
 * - Contenido absoluto
 * - Dispositivos extra grandes
 * - Dispositivos grandes
 * - Dispositivos medianos
 * - Dispositivos pequeños
 * - Dispositivos extra pequeños
 * - Dispositivos extra pequeños tamaño maximo 479px
 */

@use "./variables";

/**
 * Fondo y sombra
 * Establece las propiedades para el color de fondo y sombreado contando con valores iniciales por defecto
 */
@mixin generalShadow(
  $background: variables.$colorwhite,
  $boxShadow: 0 1px 15px 0 rgba(0, 0, 0, 0.12)
) {
  background: $background;
  box-shadow: $boxShadow;
}

/**
 * Posicionamiento absoluto
 * Define la posicion horizontal y vertical abarcando las medidas totales del elemento padre
 */
@mixin pseudoelementoTotalLimit($content: "") {
  content: $content;
  position: absolute;
  top: 0;
  left: 0;
}

/**
 * Visibilidad
 * Define el estatus de visibilidad de un elemento en el uso de transiciones
 */
@mixin statusVisibility($opacity, $visibility) {
  opacity: $opacity;
  visibility: $visibility;
}

/**
 * Input
 * Define las propiedades de un elemento input especifico de la UI
 */
@mixin inputContent(
  $background: $colorwhite,
  $border,
  $borderRadius,
  $boxShadow,
  $width
) {
  background: $background;
  border: $border;
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  width: $width;
}

/**
 * Espaciado input buscador
 * Define el espaciado izquierdo del elemento input
 */
@mixin inputSearchPadding($paddingLeft) {
  padding: 15px 15px 15px $paddingLeft;
}

@mixin shapeMeasure($height, $width) {
  height: $height;
  width: $width;
}

/**
 * Forma general
 * Establece las propiedades para definir los tamaños especificos, fondos y bordes de un elemento
 */
@mixin shapeContent($background, $border, $borderRadius, $height, $width) {
  background: $background;
  border: $border;
  border-radius: $borderRadius;
  height: $height;
  width: $width;
}

/**
 * Contenido centrado
 * Establece las propiedades para que el contenido interno este totalmente centrado en su posicion horizontal y vertical
 */
@mixin contentCenterXY {
  display: grid;
  place-items: center;
}

@mixin dFlexAlign($alignItems) {
  align-items: $alignItems;
  display: flex;
}

@mixin fontProperties($color, $fontSize, $fontWeight) {
  color: $color;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

/**
 * Contenido absoluto
 * Establece las propiedades para tener un posicionamiento de acuerdo al tamaño de su elemento padre
 */
@mixin contentAbsolute($top, $right, $bottom, $left, $transform) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  transform: $transform;
}

/**
 * Dispositivos extra grandes
 * 
 */
@mixin extra-large {
  @media (max-width: variables.$xlarge-width) {
    @content;
  }
}

/**
 * Dispositivos grandes
 * 
 */
@mixin large {
  @media (max-width: variables.$large-width) {
    @content;
  }
}

/**
 * Dispositivos medianos
 * 
 */
@mixin medium {
  @media (max-width: variables.$medium-width) {
    @content;
  }
}

/**
 * Dispositivos pequeños
 * 
 */
@mixin small {
  @media (max-width: variables.$small-width) {
    @content;
  }
}

/**
 * Dispositivos extra pequeños
 * 
 */
@mixin extra-small {
  @media (max-width: variables.$xsmall-width) {
    @content;
  }
}

/**
 * Dispositivos extra pequeños tamaño maximo 479px
 * 
 */
@mixin extra-small-479 {
  @media (max-width: variables.$xsmall479-width) {
    @content;
  }
}

@mixin button(
  $bGradient: variables.$gradientPrimary,
  $bs: variables.$shadowPrimary,
  $border: none,
  $color: variables.$colorwhite
) {
  background: $bGradient;
  box-shadow: $bs;
  border: $border;
  cursor: pointer;
  display: block;
  padding: 10px 20px;
  font-size: 16px;
  color: $color;
  border-radius: 5px;
  font-weight: 600;
  position: relative;
  transition: 0.3s;
}

@mixin linkClear($color: variables.$colorwhite) {
  color: $color;
}
