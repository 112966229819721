/**
 * _skeleton.scss
 * Estilos para el componente skeleton
 *
 * Index
 * - Skeleton contenedor pantalla completa
 * - Skeleton contenedor grid
 * - Skeleton card y skeleton card interna
 * - Skeleton card animacion
 * - Skeleton card main
 * - Skeleton card icon
 * - Skeleton card bar
 * - Skeleton card nombre del usuario
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
 * Skeleton contenedor pantalla completa
 *
 * Define un contenedor general que ocupa todo el viewport de la pantalla
 */
.complete_screen_skeleton {
  background: variables.$colorwhite;
  left: 0;
  top: 0;
  overflow-y: auto;
  position: fixed;
  z-index: 9999;
}

/**
 * Skeleton contenedor grid
 *
 * Define el contenedor flexible de las skeleton card
 */
.container_skeleton_card {
  display: grid;
  gap: 15px;
}

/**
 * Skeleton card y skeleton card interna
 *
 * Define el posicionamiento, como se debe mostrar el contenido interno y los colores como fondo
 */
.card_skeleton,
.card_skeleton_internal {
  overflow: hidden;
  position: relative;
}

.card_skeleton {
  background: variables.$colorbaseskeleton;
}

.card_skeleton_internal {
  background: variables.$colorbaseskeletoninternal;
}

/**
 * Skeleton card animacion
 *
 * Define la animacion del brillo desde el punto inicial al punto final en la posicion X
 */
.card_skeleton_animated:before {
  @include mixins.pseudoelementoTotalLimit;
  animation: animationShimmer 1.5s linear infinite;
  background: linear-gradient(
    to right,
    transparent,
    variables.$colorwhite,
    transparent
  );
  height: 100%;
  transform: translateX(-100%);
  width: 100%;
}

@keyframes animationShimmer {
  100% {
    transform: translateX(100%);
  }
}

/**
 * Skeleton card main
 */
.card_skeleton_main {
  border-radius: 10px;
  padding: 15px;
}

/**
 * Skeleton card bar
 */
.skeleton_bar {
  border-radius: 5px;
  height: 20px;
}

/**
 * Skeleton card icon
 */
.skeleton_icon {
  border-radius: 10px;
  height: 50px;
  width: 50px;
}

/**
 * Skeleton card nombre del usuario
 */
.skeleton_perfil_name {
  width: 180px;
  height: 30px;

  @include mixins.extra-small-479 {
    width: 120px;
  }
}
