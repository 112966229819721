/**
 * _header.scss
 * Estilos para el componente header del panel de módulos
 *
 * Index
 * - Contenedor general
 * - Imagen de logo
 * - Texto de nombre de sistema
 * - Contenido del perfil avatar
 * - Contenido del perfil nombre
 * - Nombre del perfil
 * - Avatar del perfil
 */

@use "../utils/variables";
@use "../utils/mixins";

.header_panel {
  @include mixins.shapeContent(variables.$colorwhite, none, 0px, 48px, 100%);
  @include mixins.dFlexAlign(center);
  padding: 6px 15px;
  position: fixed;
  top: 0;
  z-index: 1;
}

.logo_panel {
  width: 32px;
}

.name_panel {
  font-size: 24px;

  @include mixins.small {
    font-size: 20px;
  }
}

.content_perfil {
  cursor: pointer;
  position: relative;
}

.content_perfil_name {
  @include mixins.extra-small-479 {
    margin-left: 15px;
  }
}

.perfil_name {
  background: linear-gradient(
    to right,
    variables.$colorp2 50%,
    variables.$generalcolor 50%
  );
  background-clip: text;
  background-position: 100%;
  background-size: 200%, 100%;
  font-size: 16px;
  transition: 0.5s;
  -webkit-text-fill-color: transparent;

  &:hover {
    background-position: 0;
  }

  @include mixins.small {
    font-size: 14px;
  }

  @include mixins.extra-small-479 {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

.perfil_avatar {
  @include mixins.shapeContent(rgba(0, 0, 0, 0.1), none, 50%, 32px, 32px);
  @include mixins.contentCenterXY;
  color: variables.$colorp3;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:hover svg {
    color: variables.$colorwhite;
  }

  &:before {
    @include mixins.pseudoelementoTotalLimit;
    @include mixins.shapeContent(
      linear-gradient(45deg, variables.$colorp2, variables.$colorp1),
      none,
      50%,
      100%,
      100%
    );
    transform: scale(0);
    transition: 0.5s;
    z-index: -1;
  }

  &:hover:before {
    transform: scale(1);
  }

  img {
    @include mixins.shapeMeasure(32px, 32px);
    object-fit: cover;
  }
}
