/**
 * _buscador.scss
 * Estilos para el componente buscador
 *
 * Index
 * - Contenedor de componente buscador
 * - Contenedor de input buscador
 * - Contenido del input buscador
 * - Icono de input buscador
 * - Input buscador
 * - Icono de limpiar input
 */
@use "../utils/variables";
@use "../utils/mixins";

/**
 * Contenedor de componente buscador
 *
 * Define el tamaño del margen superior con modo responsive para móvil
 */
.container_search {
  margin-top: 150px;

  @include mixins.small {
    margin-top: 68px;
  }
}

/**
 * Contenedor de input buscador
 *
 * Define el tamaño del ancho y espaciado horizontal 
 */
.container_input_search {
  padding-left: 15px;
  padding-right: 15px;
  width: 800px;
}

/**
 * Contenido del input buscador
 *
 * Define el ancho total y posicionamiento padre para el contenido interno del input
 */
.content_input_search {
  position: relative;
  width: 100%;
}

/**
 * Icono de input buscador
 *
 * Establece el posicionamiento dentro del input
 */
.input_icon_search {
  @include mixins.contentAbsolute(50%, auto, auto, 15px, translateY(-50%));
  pointer-events: none;
}

/**
 * Input buscador
 *
 * Se definen los colores y sombras del input con modo responsive para móvil
 */
.input_search {
  @include mixins.inputContent(
    variables.$colorwhite,
    none,
    10px,
    0px 10px 25px rgba(0, 0, 0, 0.2),
    100%
  );

  @include mixins.small {
    font-size: 14px;
  }
}

.input_search_icon {
  @include mixins.inputSearchPadding(45px);
}

.input_search_not_icon {
  @include mixins.inputSearchPadding(15px);
}

.button_search {
  @include mixins.contentAbsolute(50%, 15px, auto, auto, translateY(-50%));
}

/**
 * Icono de - input
 *
 * Define el tamaño y color del boton e icono , el contenido interno centrado y el posicionamiento dentro del inout
 */
.input_icon_close {
  @include mixins.shapeContent(#c3c7cc, none, 50%, 20px, 20px);
  @include mixins.contentCenterXY;
  @include mixins.contentAbsolute(50%, 15px, auto, auto, translateY(-50%));

  svg {
    color: variables.$colorwhite;
    font-size: 14px;
  }
}
