/**
 * _buttons.scss
 * Estilos para los botones
 *
 * Index
 * - Boton primario
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
 * Boton primario
 *
 * Define el color del boton cuando esta activo o deshabilitado y uso de transiciones en pseudoclases y pseudolementos
 */
.button_primary {
  @include mixins.button;

  &:disabled {
    background: linear-gradient(
      45deg,
      rgb(227, 51, 51, 0.5),
      rgba(136, 36, 57, 0.5)
    );
    cursor: not-allowed;

    &:hover:before {
      @include mixins.statusVisibility(0, hidden);
      transform: scaleX(0);
    }
  }
}

.button_secondary {
  @include mixins.button(
    none,
    none,
    2px solid variables.$colorp1,
    variables.$colorp1
  );

  &:hover {
    border: 2px solid variables.$generalcolor;
    color: variables.$colorwhite;
  }

  &:disabled {
    border: 2px solid rgba(136, 36, 57, 0.5);
    color: rgba(136, 36, 57, 0.5);
    cursor: not-allowed;

    &:hover:before {
      @include mixins.statusVisibility(0, hidden);
      transform: scaleX(0);
    }
  }
}

.button_tertiary {
  @include mixins.button(none, none, none, variables.$colorp2);

  &:hover {
    color: variables.$colorwhite;
  }
}

.button_primary,
.button_secondary,
.button_tertiary {
  @include mixins.small {
    font-size: 14px;
  }

  &:hover {
    transform: scale(1.05);

    &:before {
      @include mixins.statusVisibility(1, visible);
      transform: scaleX(1);
    }

    &:disabled {
      transform: scale(1);
    }
  }

  &:before {
    @include mixins.pseudoelementoTotalLimit;
    @include mixins.statusVisibility(0, hidden);
    background: linear-gradient(
      45deg,
      variables.$colorblack,
      variables.$generalcolor
    );
    border-radius: 5px;
    height: 100%;
    pointer-events: none;
    transform: scaleX(0.4);
    transition: 0.5s;
    width: 100%;
    z-index: -1;
  }

  svg {
    margin-right: 10px;
  }

  @include mixins.small {
    padding: 8px 12px;
    width: 100%;
  }
}

a.button_primary,
a.button_secondary,
a.button_tertiary {
  display: inline-block;
  text-align: center;

  @include mixins.small {
    display: block;
  }
}
