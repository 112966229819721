.containerAlert {
  background-color: papayawhip;
  position: absolute;
  margin: 24px;
  padding: 24px;
  width: 250px;
  height: 150px;
  z-index: 1000;
  .headerContainer {
    width: 100%;
    text-align: center;
  }
  .bodyContainer {
    width: 100%;
    text-align: center;
  }
}
