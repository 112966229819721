@use "../utils/variables";
@use "../utils/mixins";

.notification_ui {
  @include mixins.shapeContent(
    rgba(255, 255, 255, 0.6),
    none,
    10px,
    auto,
    400px
  );
  @include mixins.statusVisibility(0, hidden);
  pointer-events: none;
  backdrop-filter: blur(20px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 8px 0px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 3;

  @include mixins.small {
    width: calc(100% - 30px);
  }
}

.show_notification_ui {
  pointer-events: auto;
  @include mixins.statusVisibility(1, visible);
}

.notification_ui_animation {
  animation: animateNotification 0.3s ease;
}

@keyframes animateNotification {
  0% {
    @include mixins.statusVisibility(0, hidden);
    transform: translateY(-100px) scale(0);
  }
  100% {
    @include mixins.statusVisibility(1, visible);
    transform: translateY(0px) scale(1);
  }
}

.notification_item {
  @include mixins.dFlexAlign(center);
  color: variables.$generalcolor;
  gap: 10px;
  padding: 8px 10px;
  position: relative;
  text-decoration: none;
  z-index: 0;

  &:before {
    @include mixins.pseudoelementoTotalLimit;
    @include mixins.shapeContent(rgba(50, 50, 50, 0.04), none, 0px, 100%, 100%);
    @include mixins.statusVisibility(0, hidden);
    transform: scaleX(0.5);
    transition: 0.3s;
    z-index: -1;
  }

  &:hover {
    color: variables.$generalcolor;

    &:before {
      @include mixins.statusVisibility(1, visible);
      transform: scaleX(1);
    }
  }
}

.notification_img_content {
  @include mixins.shapeContent(variables.$colorwhite, none, 50%, 60px, 60px);
  @include mixins.contentCenterXY;
  padding: 10px;

  @include mixins.small {
    @include mixins.shapeMeasure(40px, 40px);
  }

  img {
    @include mixins.shapeMeasure(100%, 100%);
  }
}

.notification_img_content_animation {
  animation: animateImage 0.2s ease;
}

@keyframes animateImage {
  0% {
    @include mixins.statusVisibility(0, hidden);
    transform: scale(0);
  }
  100% {
    @include mixins.statusVisibility(1, visible);
    transform: scale(1);
  }
}

.notification_title {
  @include mixins.fontProperties(variables.$generalcolor, 16px, 600);

  @include mixins.small {
    font-size: 14px;
  }
}

.notification_title_animation {
  @include mixins.statusVisibility(1, visible);
  animation: animateTitle 0.2s ease;
  animation-delay: 0.2s;
  transition-delay: 0.2s;
}

.notification_description {
  @include mixins.fontProperties(variables.$colorp3, 13px, 500);

  @include mixins.small {
    font-size: 12px;
  }
}

.notification_description_animation {
  @include mixins.statusVisibility(1, visible);
  animation: animateTitle 0.2s ease;
  animation-delay: 0.4s;
  transition-delay: 0.4s;
}

@keyframes animateTitle {
  0% {
    @include mixins.statusVisibility(0, hidden);
    transform: translateY(-15px);
  }
  100% {
    @include mixins.statusVisibility(1, visible);
    transform: translateY(0px);
  }
}

.notification_count {
  @include mixins.shapeContent(variables.$colorerror, none, 50%, 18px, 18px);
  @include mixins.contentCenterXY;
  @include mixins.fontProperties(variables.$colorwhite, 12px, 500);
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
}
