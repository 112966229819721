/**
 * _login.scss
 * Estilos para el componente login
 *
 * Index
 * - Contenedor general grid
 * - Cards
 * - Input
 * - Boton de icono
 * - Contenedor de logo móvil
 * - Texto nombre del sistema móvil y escritorio
 * - Contenedor de logo escritorio
 * - Texto nombre del sistema escritorio
 * - Imagen de fondo pantalla parcial
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
 * Contenedor general grid
 * Establece la grid para estructurar sus elementos internos en columnas con su modo responsive
 */
.container_authentication {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;

  @include mixins.small {
    grid-template-columns: 1fr;
  }
}

/**
 * Cards
 * Define los tamaños de las cards utilizadas en login y cambio de contraseña
 */
.card_authentication,
.card_change_password {
  width: 500px;
}

/**
 * Cards
 * Establece los modos responsive para las cards
 */
.card_authentication {
  @include mixins.large {
    width: 100%;
  }
}

.card_change_password {
  @include mixins.small {
    width: 100%;
  }
}

/**
 * Input
 * Establece el borde y espaciado de los inputs para inicio de sesion, cambio y restauracion de contraseñas
 */
.input_authentication {
  @include mixins.inputContent(
    none,
    1px solid rgba(0, 0, 0, 0.1),
    10px,
    none,
    100%
  );
  padding: 10px 12px;
}

/**
 * Boton de icono
 * Establece el posicionamiento del boton mostrar contraseña para el centrado vertical dentro del input
 */
.button_icon_password {
  @include mixins.contentAbsolute(50%, 15px, auto, auto, translateY(-50%));
}

/**
 * Contenedor de logo móvil
 * Establece el fondo, espaciado de la card ademas el tamaño del logo y tamaño de fuente del nombre del sistema para dispositivo móvil
 */
.card_logo_mobile {
  background: variables.$colorblack;
  border-radius: 10px;
  padding: 15px;

  .logo_mobile {
    width: 50px;
  }

  .name_system_mobile {
    font-size: 32px;
  }
}

/**
 * Texto nombre del sistema móvil y escritorio
 * Establece el color del texto para ambos tipos de dispositivos
 */
.card_logo_mobile .name_system_mobile,
.complete_screen_logo .name_system_desktop {
  color: variables.$colorwhite;
}

/**
 * Contenedor de logo escritorio
 *  Establece el tamaño del logo y tamaño de fuente del nombre del sistema para dispositivo de escritorio y su modo responsive
 */
.complete_screen_logo {
  .logo_desktop {
    width: 150px;

    @include mixins.large {
      width: 120px;
    }

    @include mixins.medium {
      width: 100px;
    }
  }

  .name_system_desktop {
    font-size: 100px;

    @include mixins.large {
      font-size: 70px;
    }

    @include mixins.medium {
      font-size: 50px;
    }
  }
}

/**
 * Imagen de fondo pantalla parcial
 *  Establece la imagen que se tiene de fondo para el inicio de sesion y restaurar contraseña
 */
.complete_screen_logo {
  background-image: url("../../../assets/img/pattern_logo_panamotors.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
