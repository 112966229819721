/**
 * _reset.scss
 * Estilos generales para resetear las propiedades de selectores
 *
 * Index
 * - Reseteo de propiedades selector universal
 * - Reseteo de propiedades de enlaces
 * - Reseteo de propiedades de botones
 * - Reseteo de pseudoclase focus de boton
 */

/**
 * Reseteo de propiedades selector universal
 *
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/**
 * Reseteo de propiedades de enlaces
 *
 */
a {
  text-decoration: none;
}

/**
 * Reseteo de propiedades de botones
 *
 */
button {
  background: none;
  border: none;
}

/**
 * Reseteo de pseudoclase focus de button e input
 *
 */

input:focus,
button:focus {
  outline: 0;
}
