/**
 * _moduloNoEncontrado.scss
 * Estilos para el componente modulo no encontrado
 *
 * Index
 * - Contenedor general
 * - Imagen
 * - Texto
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
 * Contenedor general
 *
 * Define el margen superior y espaciado interno del contenedor y su modo responsive para dispositivo móvil
 */
.container_module_not_found {
  margin-top: 100px;
  padding-left: 15px;
  padding-right: 15px;

  @include mixins.small {
    margin-top: 30px;
  }
}

/**
 * Imagen
 *
 * Define el tamaño de la imagen con su modo responsive para dispositivo móvil
 */
.image_module_not_found {
  width: 200px;

  @include mixins.small {
    width: 150px;
  }
}

/**
 * Texto
 *
 * Define el tamaño del texto principal y su alineacion horizontal incluyendo su modo responsive
 */
.text_module_not_found {
  font-size: 32px;
  font-weight: bold;
  overflow-wrap: break-word;
  text-align: center;

  @include mixins.small {
    font-size: 24px;
  }
}
