/**
 * _dropdowns.scss
 * Estilos para el dropdown
 *
 * Index
 * - Menu desplegable oculto
 * - Menú desplegable a la izquierda
 * - Menu desplegable a la derecha
 * - Mostrar menú desplegable
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
 * Menú desplegable oculto
 *
 * Establece el menu desplegable oculto a traves de su visibilidad y escala, ademas de
 * las propiedades de sus elementos internos como iconos y enlaces
 */
.dropdown_menu {
  @include mixins.statusVisibility(0, hidden);
  @include mixins.shapeContent(variables.$colorwhite, none, 10px, auto, 200px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: absolute;
  top: calc(100% + 5px);
  transform: scale(0.5);
  transition: 0.2s ease-in;
  z-index: 3;

  svg {
    margin-right: 5px;
  }

  li,
  a {
    align-items: center;
    display: flex;
    padding: 8px 10px;

    &:hover {
      background: variables.$colorp5;
    }
  }

  li {
    cursor: pointer;
    list-style: none;
  }

  a {
    color: variables.$generalcolor;
  }
}

/**
 * Menú desplegable a la izquierda
 *
 * Define el posicionamiento absoluto en 0px a la izquierda dentro de su contenedor padre
 */
.dropdown_menu_left {
  left: 0;
}

/**
 * Menú desplegable a la derecha
 *
 * Define el posicionamiento absoluto en 0px a la derecha dentro de su contenedor padre
 */
.dropdown_menu_right {
  right: 0;
}

/**
 * Mostrar menú desplegable
 *
 * Define las propiedades para realizar la transición al activar la visibilidad y escala del menú
 */
.show_dropdown_menu {
  @include mixins.statusVisibility(1, visible);
  transform: scale(1);
}
