@use "../utils/variables";
@use "../utils/mixins";

.container_input_code {
  display: flex;
}

.content_input_code {
  position: relative;

  &:before {
    background: variables.$colorp4;
    content: "";
    height: 2px;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
  }

  + .content_input_code {
    margin-left: 15px;
  }

  &:last-of-type:before {
    height: 0px;
    width: 0px;
  }
}

.input_code {
  @include mixins.shapeContent(
    variables.$colorwhite,
    2px solid variables.$colorp4,
    5px,
    50px,
    50px
  );
  text-align: center;
  transition: 0.5s;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }

  &:focus {
    border: 2px solid variables.$colorp3;
    box-shadow: 0px 0px 0px 2px variables.$colorp3;
  }
}
