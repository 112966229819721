/**
 * _alertas.scss
 * Estilos para el componente alertas
 *
 * Index
 * - Contenedor general
 * - Mostrar contenedor general
 * - Contenido de alerta
 * - Contenedor de forma circular
 * - Forma circular con icono
 * - Color de fondo en loader cargando
 * - Forma circular fondo
 * - Animaciones del fondo forma circular
 * - Animacion de alerta
 * - Alertas contenedor de texto
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
  * Contenedor general
  *
  * Define un contenedor general oculto que ocupa todo el viewport de la pantalla
  */
.container_alert {
  @include mixins.statusVisibility(0, hidden);
  background: variables.$colorwhite;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: scale(0.6);
  transition: 0.5s;
  z-index: 9999;
}

/**
  * Mostrar contenedor general
  *
  * Muestra el contenedor general oculto en todo el viewport de la pantalla
  */
.show_container_alert {
  @include mixins.statusVisibility(1, visible);
  transform: scale(1);
}

/**
  * Contenido de alerta
  *
  * Define el ancho para el contenido del alerta
  */
.content_alert {
  max-width: 500px;
  z-index: 1;
}

/**
  * Contenedor de forma circular
  *
  * Define la posicion central de la forma circular dentro del contenido
  */
.container_shape_alert {
  @include mixins.contentCenterXY;
}

/**
  * Forma circular con icono
  *
  * Define las medidas de la forma y posicionamiento central del icono 
  */
.shape_alert {
  @include mixins.shapeContent(none, none, 50%, 100px, 100px);
  @include mixins.contentCenterXY;
  position: relative;

  svg {
    color: variables.$colorwhite;
    font-size: 70px;
    z-index: 1;
  }
}

.shape_loader {
  @include mixins.shapeMeasure(50px, 50px);
}

/**
  * Forma circular fondo
  *
  * Define las medidas y posicionamiento para el color de fondo
  */
.shape_alert_bg,
.shape_loader_bg {
  @include mixins.shapeContent(none, none, 50%, 100%, 100%);
  @include mixins.pseudoelementoTotalLimit(none);
}

/**
  * Color de fondo en loader cargando
  *
  * Define el color base para el loader
  */
.shape_loader_bg {
  background: variables.$colorp6;
}

/**
  * Animaciones del fondo forma circular
  *
  * Establece animaciones al mostrar el alerta y loader con el uso de pseudoelementos
  */
.animate_shape_alert_bg,
.animate_shape_loader {
  &:before {
    @include mixins.contentAbsolute(-15px, -15px, -15px, -15px, none);
    @include mixins.generalShadow(
      rgba($color: variables.$generalcolor, $alpha: 0.06),
      (
        0px 0px 0px 15px rgba($color: variables.$generalcolor, $alpha: 0.04),
        0px 0px 0px 30px rgba($color: variables.$generalcolor, $alpha: 0.02)
      )
    );
    border-radius: 50%;
    content: "";
  }

  &:after {
    @include mixins.contentAbsolute(-15px, -15px, -15px, -15px, none);
    animation: animateAlertAfter 3s linear infinite;
    border-bottom: 5px rgba($color: variables.$generalcolor, $alpha: 0.1) solid;
    border-left: 5px transparent solid;
    border-radius: 50%;
    border-right: 5px transparent solid;
    border-top: 5px rgba($color: variables.$generalcolor, $alpha: 0.1) solid;
    content: "";
  }
}

/**
  * Animacion de alerta
  *
  * Establece la animacion unicamente en el componente de alertas
  */
.animate_shape_alert_bg {
  animation: animateShapeAlert 0.5s ease-in-out;
}

@keyframes animateShapeAlert {
  0% {
    transform: scale(5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animateAlertAfter {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**
  * Alertas contenedor de texto
  *
  * Estable la alineacion horizontal en la que se encuentra el texto
  */
.container_text_alert {
  text-align: center;
}

/*-------------------------------------------*\
---------- Alertas de confirmacion ---------
\*-------------------------------------------*/

.container_confirmation_alert {
  @include mixins.statusVisibility(0, hidden);
  @include mixins.contentCenterXY;
  background: variables.$colorwhite;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transform: scale(0.8);
  transition: 0.5s;
  width: 100%;
  z-index: 3;
}

.show_container_confirmation_alert {
  @include mixins.statusVisibility(1, visible);
  transform: scale(1);
}

.content_confirmation_alert {
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 17%);
  padding: 40px 15px;
  transform: scale(2);
  transition: 0.3s ease;
  width: 500px;

  @include mixins.extra-small {
    width: calc(100% - 30px);
  }
}

.show_content_confirmation_alert {
  transform: scale(1);
  animation: animateConfirmationAlert 0.8s linear;
}

@keyframes animateConfirmationAlert {
  0% {
    transform: scale(2);
  }

  33% {
    transform: scale(1);
  }

  66% {
    transform: scale(1.3);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.confirmation_alert_text {
  font-size: 16px;
}

// .confirmation_alert_actions {
//   @include mixins.dFlexAlign(center);
//   gap: 15px;
//   margin-top: 20px;
// }

.confirmation_alert_actions {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}
/*-------------------------------------------*\
---------- Alertas de textos ---------
\*-------------------------------------------*/

.popup_alert_text {
  @include mixins.statusVisibility(0, hidden);
  background: variables.$colorwhite;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba($color: variables.$generalcolor, $alpha: 0.15);
  font-size: 12px;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  width: 250px;
  z-index: 2;
}

.popup_alert_text_left {
  left: 0;
}

.popup_alert_text_right {
  right: 0;
}

.show_popup_alert_text_left {
  @include mixins.statusVisibility(1, visible);
  left: calc(100% + 5px);
}

.show_popup_alert_text_right {
  @include mixins.statusVisibility(1, visible);
  right: calc(100% + 5px);
}

.container_alert_message {
  border: 1px solid rgb(244, 245, 247);
  border-radius: 10px;
}

.content_alert_message {
  background: #fbfbfb;
  padding: 12px;
}

.alert_message_img {
  width: 30px;
}

.alert_message_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
