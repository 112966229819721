/**
 * _resturarPassword.scss
 * Estilos para el componente de restaurar contraseña
 *
 * Index
 * - Contenedor general de los radio buttons
 * - Grupo de radio buttons grid
 * - Input radio
 * - Label del radio
 * - Texto mensaje de error
 */

@use "../utils/variables";
@use "../utils/mixins";

/**
 * Contenedor general de los radio buttons
 *
 * Define el fondo y espaciado para el contenido interno que ocupan los radio buttons
 */
.container_group_radio_check {
  background: rgba(variables.$generalcolor, 0.07);
  border-radius: 10px;
  padding: 5px;
}

/**
 * Grupo de radio buttons grid
 *
 * Define la estructura grid para la columna que ocupa cada radio button
 */
.group_radio_check {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

/**
 * Input radio
 *
 * Define el posicionamiento del radio button y crea un corte que sirve para ocultar el elemento,
 * ademas de activar la pseudoclase checked para mostrar la transicion en pseudoelemento before del selector label
 */
.input_radio_check {
  clip-path: inset(10px);
  position: absolute;

  &:checked ~ .label_radio_check:before {
    transform: translate(-50%, -50%) scale(1);
  }
}

/**
 * Label del radio
 *
 * Define el posicionamiento y ancho del label que contiene una transition en pseudoelemento activandola al tener activo el checked del input
 */
.label_radio_check {
  border-radius: 10px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;

  &:before {
    @include mixins.contentAbsolute(
      50%,
      auto,
      auto,
      50%,
      translate(-50%, -50%) scale(0)
    );
    @include mixins.shapeContent(
      variables.$colorwhite,
      none,
      50%,
      500px,
      500px
    );
    content: "";
    transition: 0.5s;
    z-index: -1;
  }
}

/**
 * Texto mensaje de error
 *
 * Tamaño y color del texto que origina un error en el input
 */
.form_text_error {
  color: variables.$colorp2;
  font-size: 12px;
}
